import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

const ComingSoonSplash = styled("div")`
  container-type: inline-size;
  container-name: champion-soon;

  .content {
    --radii: var(--br-xl);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
    padding: var(--sp-10);
    padding-top: 25rem;
    background: var(--shade10);
    border-radius: var(--radii);
    text-shadow: 0 2px 7px var(--shade9);
    contain: paint;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--radii);
      box-shadow: inset 0 0 0 1px var(--shade0-15);
      pointer-events: none;
    }

    @container champion-soon (max-width: 600px) {
      padding: var(--sp-6);
      padding-top: 20rem;
    }

    @container champion-soon (max-width: 400px) {
      padding: var(--sp-4);
      padding-top: 13rem;
    }

    > * {
      position: relative;
    }

    .title,
    .subtitle,
    hr {
      position: relative;
      max-width: 60ch;
    }
    hr {
      width: 60px;
      height: 4px;
      margin: 0;
      border: none;
      background: var(--primary);
    }
    .subtitle {
      color: var(--shade0-75);
    }
    .bg-container {
      position: absolute;
      inset: 0;
      border-radius: var(--br);
      overflow: hidden;
    }
    .bg {
      --blur: 5px;
      bottom: calc(var(--blur) * -1);
      left: calc(var(--blur) * -1);
      height: 120%;
      width: auto;
      opacity: 25%;
      filter: blur(var(--blur));
      -webkit-mask-image: linear-gradient(
        to bottom,
        hsl(0deg 0% 0% / 70%) 0%,
        black 70%
      );
    }
  }
`;

type ComingSoonProps = {
  title: Translation;
  subtitle: Translation;
  description?: Translation;
  className: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

function ChampionComingSoon({
  title,
  subtitle,
  description,
  className,
  style = {},
  children,
}: ComingSoonProps) {
  const { t } = useTranslation();

  return (
    <ComingSoonSplash className={className} style={style}>
      <div className="content">
        {children}
        <h1 className="type-h3 title">{t(...title)}</h1>
        <hr />
        <p className="type-body1 subtitle">{t(...subtitle)}</p>
        {description && (
          <p className="type-caption" style={{ color: "var(--shade1)" }}>
            {t(...description)}
          </p>
        )}
      </div>
    </ComingSoonSplash>
  );
}

export default ChampionComingSoon;
