import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.js";

import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import { Container } from "@/game-lol/components/SimilarChampions.style.jsx";
import {
  CHAMPIONS,
  CHAMPIONS_KEY_TO_ID,
} from "@/game-lol/constants/champion-classes.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { getStaticData } from "@/game-lol/utils/util.mjs";

function SimilarChampions({ championId, champion }) {
  const { t } = useTranslation();
  const champions = getStaticData("champions");

  const championName = champion?.name || CHAMPIONS[championId]?.key;
  const championClasses = CHAMPIONS[championId];

  if (!championClasses) return null;

  const primaryClass = championClasses.primaryClass;
  const subClass = championClasses.subClass;
  const similarChampions = Object.values(CHAMPIONS)
    .map((championInfo) => {
      const samePrimaryClass = championInfo.primaryClass === primaryClass;
      const sameSubClass = championInfo.subClass === subClass;

      let points = 0;
      if (samePrimaryClass) points += 2;
      if (sameSubClass) points += 1;

      return {
        id: CHAMPIONS_KEY_TO_ID[championInfo.key],
        key: championInfo.key,
        points,
      };
    })
    .filter(
      (championInfo) => championInfo.points && championInfo.id !== championId,
    );

  if (!similarChampions.length) return null;

  return (
    <Card
      padding="0"
      title={t(
        "lol:similarChampionsToChampName",
        "Similar champions to {{champion}}",
        { champion: championName },
      )}
      subtitle={t(
        "lol:similarChampionsSubtitle",
        "Explore champions similar to {{champion}} and expand your champion pool with ease. Our Similar Champions section introduces you to characters with comparable playstyles, ensuring you find the perfect match for your gaming preferences.",
        { champion: championName },
      )}
    >
      <ul className={Container()}>
        {similarChampions.slice(0, 6).map((championInfo) => {
          const championKey = CHAMPIONS[championInfo.id]?.key;
          if (!championKey) return null;

          const championName = champions[championKey]?.name;

          return (
            <a
              key={championInfo.id}
              href={`/${lolRefs.lolChampionPrefix}/${championKey}/build`}
            >
              <ChampionImg championId={championInfo.id} size={62} />
              <span className="type-callout--semi">
                {championName || championKey}
              </span>
            </a>
          );
        })}
      </ul>
    </Card>
  );
}

export default SimilarChampions;
